<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">造品赋能·方案详情</div>
      <div class="sub-title">造品赋能增长策略</div>
      <div class="large-paragraph-describe">
        造悟说通过将全球蛙大数据与营销能力、以及洛可可爆品研发与创新设计能力整合，构建以用户参与、数据驱动的产品创新与全渠道营销体系，并推出“爆品造物计划”，助力品牌商打造自有品牌、国货潮品、IP联名新品，使品牌商商品品类矩阵更丰富、商品性价比更高、毛利更高，同时也更贴合消费者生活，促进消费新需求，激发增长新活力。
      </div>
    </div>
    <img
      class="large-image-h928"
      src="@/image/brand/img_three_products_brand@3x.png"
    />
    <img
      class="large-image-h1254"
      src="@/image/brand/img_seven_products_brand@3x.png"
    />
    <div class="func-module-container">
      <div class="func-title">造品赋能·应用案例</div>
      <div class="func-sub-title">造悟说助力品牌商打造亿元新爆品</div>
    </div>
    <div class="large-image-container">
      <img
        class="large-image-h728"
        src="@/image/brand/img_yz_products_brand@3x.png"
      />
      <img
        class="large-image-h728"
        src="@/image/brand/img_mp_products_brand@3x.png"
        style="margin-top: 20px"
      />
    </div>
    <action-sheet module="新品牌造品赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    subscriptImages: [
      require("@/image/retail/img_brand_produce@3x.png"),
      require("@/image/retail/img_market_produce@3x.png"),
      require("@/image/retail/img_channel_produce@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-paragraph-describe {
  margin-bottom: 0 !important;
}
.large-image-h868 {
  margin-top: 40px;
}
.large-image-h1194 {
  margin-bottom: 30px;
}
.large-image-container {
  padding: 0 !important;
  padding-bottom: 60px !important;
}
</style>
